<template>
  <div class="table-responsive d-none d-md-block">
    <table class="table table-striped fs-sm table-borded">
      <thead>
        <tr>
          <th>
            <p class="mb-0">Parcela</p>
          </th>
          <th>
            <p class="mb-0">Vencimento</p>
          </th>
          <th>
            <p class="mb-0">Valor</p>
          </th>

          <th>
            <p class="mb-0">Juros</p>
          </th>
          <th>
            <p class="mb-0">Multa</p>
          </th>
          <th>
            <p class="mb-0">Correção</p>
          </th>
          <th>
            <p class="mb-0">Status</p>
          </th>
          <th>
            <p class="mb-0">Data da Baixa</p>
          </th>
          <th>
            <p class="mb-0">Total</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="statement in statements" :key="statement.idLan">
          <td>
            <p class="mb-0">{{ statement.description }}</p>
          </td>
          <td>
            <p class="mb-0">{{ statement.dueDate }}</p>
          </td>
          <td>
            <p class="mb-0">{{ statement.valueOriginal }}</p>
          </td>

          <td>
            <p class="mb-0">{{ statement.valueFine }}</p>
          </td>
          <td>
            <p class="mb-0">{{ statement.valueFees }}</p>
          </td>
          <td>
            <p class="mb-0">{{ statement.currencyCorrection }}</p>
          </td>
          <td>
            <p
              class="mb-0"
              v-html="statusStatementsTranslate(statement.status)"
            ></p>
          </td>
          <td>
            <p class="mb-0">{{ statement.writeOff }}</p>
          </td>
          <td>
            <p class="mb-0">{{ statement.total }}</p>
          </td>
        </tr>
        <tr v-if="statements.lenght === 0">
          <td colspan="9">Nenhum lançamento financeiro encontrado.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="table-responsive d-block d-md-none">
    <table class="table table-striped table-xs fs-xs table-borded">
      <thead>
        <tr>
          <th class="p-1">
            <p class="mb-0">Parcela</p>
            <p class="mb-0">Vencimento</p>
          </th>
          <th class="p-1">
            <p class="mb-0">Valor</p>
            <p class="mb-0">Correção</p>
          </th>
          <th class="p-1">
            <p class="mb-0">Juros</p>
            <p class="mb-0">Multa</p>
          </th>
          <th class="p-1">
            <p class="mb-0">Status</p>
          </th>
          <th class="p-1">
            <p class="mb-0">Data da Baixa</p>
          </th>
        </tr>
      </thead>
      <tbody class="scroll-mobile">
        <tr v-for="statement in statements" :key="statement.idLan">
          <td class="p-1">
            <p class="mb-0 fw-bolder">{{ statement.description }}</p>
            <p class="mb-0">{{ statement.dueDate }}</p>
          </td>
          <td class="p-1">
            <p class="mb-0">{{ statement.valueOriginal }}</p>
            <p class="mb-0">{{ statement.currencyCorrection }}</p>
          </td>
          <td class="p-1">
            <p class="mb-0">{{ statement.valueFine }}</p>
            <p class="mb-0">{{ statement.valueFees }}</p>
          </td>
          <td class="p-0">
            <p
              class="mb-0"
              v-html="statusStatementsTranslate(statement.status)"
            ></p>
          </td>
          <td class="p-1">
            <p class="mb-0">{{ statement.writeOff }}</p>
            <p class="mb-0">{{ statement.total }}</p>
          </td>
        </tr>
        <tr v-if="statements.lenght === 0">
          <td colspan="5">Nenhum lançamento financeiro encontrado.</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style>
.scroll-mobile {
  height: calc(100vh - 180px);
}
</style>
<script>
import { statusStatementsTranslate } from '@/utils/strings'

export default {
  name: 'ListStatements',

  props: {
    statements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      statusStatementsTranslate,
    }
  },
  methods: {},
  mounted() {},
}
</script>
