<template>
  <LoadingData v-if="loadData" />
  <div v-else class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link to="/financials"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-files me-2" /> Boletos em Aberto
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Boletos em Aberto</h6>
          <p>Exibe os boletos em aberto com a SN em pdf e código de barras</p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <div v-if="billets.length > 0"><list-billets :billets="billets" /></div>

      <div v-else>
        <p class="text-center">Nenhum boleto disponível.</p>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import ListBillets from '@/components/financials/ListBillets.vue'
import Popper from 'vue3-popper'

export default {
  name: 'FinancialBillets',

  components: {
    LoadingData,
    ListBillets,
    Popper,
  },

  data() {
    return {
      loadData: true,
      billets: [],
      unity: this.$store.getters.unity,
    }
  },

  methods: {
    async getBillets() {
      try {
        this.billets = await this.$store.dispatch(
          'getFinancialBillets',
          this.unity,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getBillets()
  },
}
</script>
