<template>
  <LoadingData v-if="loadData" />
  <div v-else class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link to="/financials"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-list-bullets me-2" /> Extrato Financeiro
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Extrato Financeiro</h6>
          <p>
            Exibe o histórico de parcelamentos (parcelas pagas/parcelas em
            aberto) com a SN
          </p>
        </template>
      </Popper>
    </div>
    <div class="card-body p-0">
      <ListStatments :statements="statements"></ListStatments>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import ListStatments from '@/components/financials/ListStatements.vue'
import Popper from 'vue3-popper'

export default {
  name: 'Statements',

  components: {
    LoadingData,
    ListStatments,
    Popper,
  },

  data() {
    return {
      loadData: true,
      statements: [],
      unity: this.$store.getters.unity,
    }
  },

  methods: {
    async getStatements() {
      try {
        this.statements = await this.$store.dispatch(
          'getFinancialStatements',
          this.unity,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getStatements()
  },
}
</script>
