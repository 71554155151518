<template>
  <LoadingData v-if="loadData" />
  <div v-else class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link to="/financials"
          ><i class="ph-caret-left"></i
        ></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-scroll me-2" /> Informe de Rendimentos
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Declaração de Imposto de Renda</h6>
          <p>
            Aqui são listados as declarações de imposto de renda relacionadas a
            sua compra.
          </p>
        </template>
      </Popper>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>Ano</th>
              <th class="text-end">Download</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="year in years" :key="year">
              <td>{{ year }}</td>
              <td class="p-0 text-end">
                <button
                  class="btn btn-link bg-secondary bg-opacity-10 text-secondary lh-1 rounded-pill p-2 me-4"
                  :disabled="disableButton"
                  @click="downloadIR(year)"
                >
                  <i class="ph-download-simple"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'FinancialIncomeTax',

  components: {
    LoadingData,
  },

  data() {
    return {
      disableButton: false,
      loadData: false,
      years: [],
      ir64: null,
      unity: this.$store.getters.unity,
    }
  },

  methods: {
    async getIRPFYears() {
      try {
        this.years = await this.$store.dispatch('getIncomeTaxYears', this.unity)
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
    async downloadIR(year) {
      try {
        this.disableButton = true
        this.ir64 = await this.$store.dispatch('getIncomeTaxPDF', {
          year,
          unity: this.unity,
        })
        this.downloadPDF()
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },
    downloadPDF() {
      const linkSource = `data:application/pdf;base64,${this.ir64}`
      const downloadLink = document.createElement('a')
      const fileName = 'InformeDeRendimentos.pdf'

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  },

  mounted() {
    this.getIRPFYears()
  },
}
</script>
