<template>
  <div v-for="billet in billets" :key="billet.id">
    <div class="d-flex align-item-center">
      <div class="d-flex flex-fill align-items-center">
        <div>
          <h6 class="mb-0">Vencimento: {{ billet.dueDate }}</h6>
          <p class="mb-0">Valor: {{ billet.value }}</p>
        </div>
      </div>
      <div class="d-flex flex-fill align-items-center d-none d-md-flex">
        <div>
          <p class="mb-0">Código de Barras: {{ billet.barCode }}</p>
        </div>
      </div>
      <div class="text-end">
        <button
          class="btn bg-secondary bg-opacity-10 text-secondary lh-1 rounded-pill p-2 me-2"
          title="Copiar Código de Barras"
          :disabled="disableButton"
          @click="copyBarCode(billet)"
        >
          <i class="ph-barcode"></i>
        </button>
        <button
          class="btn bg-secondary bg-opacity-10 text-secondary lh-1 rounded-pill p-2 me-2"
          title="Fazer Download do Boleto"
          :disabled="disableButton"
          @click="downloadBillet(billet)"
        >
          <i class="ph-file-pdf"></i>
        </button>
      </div>
    </div>
    <div class="">
      <hr class="my-2" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListBillets',

  props: {
    billets: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      disableButton: false,
      canCopy: false,
      billet: null,
      billet64: null,
    }
  },

  methods: {
    async copyBarCode(billet) {
      if (this.canCopy) {
        this.disableButton = true
        await navigator.clipboard.writeText(billet.barCode)
        this.$root.$refs.notification.success(
          `Código de barras copiado com sucesso!`,
        )
        this.disableButton = false
      } else {
        this.$root.$refs.notification.warning(
          `Atenção! Seu dispositivo não permite copiar o código de barras.`,
        )
      }
    },
    async downloadBillet(billet) {
      try {
        this.disableButton = true
        this.billet = billet
        this.billet64 = await this.$store.dispatch('getBilletPDF', billet)
        this.downloadPDF()
        this.disableButton = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
        this.$emit('closeModal', false)
      }
    },
    downloadPDF(pdf) {
      const linkSource = `data:application/pdf;base64,${this.billet64}`
      const downloadLink = document.createElement('a')
      const fileName = 'boleto.pdf'

      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    },
  },

  mounted() {
    this.canCopy = !!navigator.clipboard
  },
}
</script>
