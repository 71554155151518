<template>
  <div class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link to="/"><i class="ph-caret-left"></i></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-currency-dollar me-2" /> Financeiro
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Financeiro</h6>
          <p>
            Na página financeiro é possível consultar seus boletos em aberto,
            extrato, informe de rendimentos, etc.
          </p>
        </template>
      </Popper>
    </div>
    <LoadingData v-if="loadData" class="my-4" />
    <div v-else class="card-body">
      <div class="row align-items-center">
        <div class="col-12 col-md-4">
          <GeneralChart />
        </div>
        <div class="col-12 col-md-8 text-secondary">
          <h5><strong>Resumo Financeiro:</strong></h5>

          <p>
            <strong>Valor em Aberto: </strong> {{ financial.debtorValueMoney }}
          </p>
          <p><strong>Valor Pago: </strong> {{ financial.paidValueMoney }}</p>
          <p><strong>Total: </strong> {{ financial.snTotalMoney }}</p>
          <p>
            <strong>Valor Financiado em Instituição Financeira: </strong>
            {{ financial.bankFinancedMoney }}
          </p>
        </div>
        <div class="col-12">
          <hr class="mb-0" />
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div
          class="col-12 col-md-6"
          v-for="(shortcut, key) in shortcuts"
          :key="key"
        >
          <router-link :to="shortcut.route">
            <div class="d-flex align-item-center mb-2">
              <div class="me-3">
                <div
                  class="bg-secondary bg-opacity-10 text-secondary lh-1 rounded-pill p-2"
                >
                  <i :class="shortcut.icon"></i>
                </div>
              </div>
              <div class="d-flex flex-fill align-items-center">
                <h6 class="mb-0">{{ shortcut.name }}</h6>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import GeneralChart from '@/components/financials/GeneralChart.vue'
import Popper from 'vue3-popper'

export default {
  name: 'Home',

  components: {
    LoadingData,
    GeneralChart,
    Popper,
  },

  data() {
    return {
      loadData: true,
      financial: null,
      unity: this.$store.getters.unity,
      shortcuts: [
        {
          name: 'Boletos em Aberto',
          icon: 'ph-files',
          route: '/financials/billets',
        },
        {
          name: 'Extrato',
          icon: 'ph-list-bullets',
          route: '/financials/statements',
        },
        {
          name: 'Informe de Rendimentos',
          icon: 'ph-scroll',
          route: '/financials/income-tax',
        },
        {
          name: 'Histórico de Índice de Correção',
          icon: 'ph-chart-line',
          route: '/financials/readjustments',
        },
      ],
    }
  },

  methods: {
    async getGeneralsInfos() {
      try {
        this.financial = await this.$store.dispatch(
          'getFinancialGeneral',
          this.unity,
        )
        this.chartData = [
          {
            name: 'Em Aberto',
            value: this.financial.debtorValue,
            money: this.financial.debtorValueMoney,
            itemStyle: {
              color: '#669EB9',
            },
          },
          {
            name: 'Pago',
            value: this.financial.paidValue,
            money: this.financial.paidValueMoney,
            itemStyle: {
              color: '#247297',
            },
          },
        ]
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getGeneralsInfos()
  },
}
</script>
